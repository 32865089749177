import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import IndexSPA from "../../components/IndexSPA"

const Index = (props) => {
   return (
      <>
        <Helmet
          title="社宅公共藝術計劃命名活動 - LOGIN@_ / 藝術與空間的碰撞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "Login，作為社宅+公共藝術，成為臺北藝術生活聚落之命名提案。",
            },
            {
              property: `og:title`,
              content: "社宅公共藝術計劃命名活動 - LOGIN@_ / 藝術與空間的碰撞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "Login，作為社宅+公共藝術，成為臺北藝術生活聚落之命名提案。",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../../images/422socialimage.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 
 export default Index;